export enum ROUTES {
  SIGN_IN = "/sign-in",
  SIGN_UP = "/sign-up",
  MAIN = "/",
  EMPTY = "/",
  CONFIRM = "join/confirm/",
  PROFILE = "/:nickname",
  MY_PROFILE = "/",
  FOLLOWERS = "/:nickname/followers",
  SUBSCRIPTIONS = "/:nickname/follower",
  NEW_POST = "/create/p",
  PREPARE_NEW_POST = "/create/prepare/p",
  POST = "/p",
  SETTINGS = "/settings",
  SETTING_CHANGE_PASSWORD = "/settings/change-password",
  SETTING_CHANGE_LANGUAGE = "/settings/change-language",
  EDITE_POST = "/edit/p",
  PAGE_NOT_FOUND = "*",
  CHALLENGES = "/challenges",
  CURRENT_CHALLENGE = "/challenge",
  CREATE_CHALLENGE = "/create-challenge",
  PARTICIPATE_CHALLENGE = "/participate-challenge",
  EDIT_CHALLENGE = "/edit-challenge",
}
