import React from "react";
import { IChallenge } from "interfaces";
import styles from "./styles.module.css";
import { cutText } from "shared/utils";
import { NavLink } from "react-router-dom";
import { ROUTES } from "app/constants";

interface IProps {
  challenge: IChallenge;
}

export const Challenge = ({ challenge }: IProps) => {
  return (
    <NavLink
      to={`${ROUTES.CURRENT_CHALLENGE}/${challenge.id}`}
      className={"h-[120px] flex flex-row mb-[20px] w-full gap-10"}
    >
      <img
        src={challenge?.filePath}
        alt={"challenge preview"}
        className={"min-h-[120px] min-w-[170px] w-[170px] h-[120px]"}
      />
      <div className={"h-full w-full flex flex-col justify-center"}>
        <span className={`${styles.ellipsis} font-medium`}>
          {cutText(challenge?.name, 36)}
        </span>
        <span className={styles.ellipsis}>
          {cutText(challenge?.description, 100)}
        </span>
      </div>
    </NavLink>
  );
};
