import React from "react";
import { ReactionButton } from "shared/ui";
import { ReactComponent as ShareIcon } from "assets/icons/share.svg";
import { ReactComponent as TabIcon } from "assets/icons/tab.svg";
import { IChallengeInfo } from "interfaces";

interface IProps {
  info: IChallengeInfo;
  onLike: () => void;
  onDislike: () => void;
  onTab: () => void;
  onParticipate: () => void;
}

export const Content = ({ info }: IProps) => {
  return (
    <div>
      <div>
        <img
          alt={"challenge image"}
          className={"w-full min-h-[300px]"}
          src={info?.challenge?.filePath}
        />
        <div className={"flex justify-between"}>
          <div className={"flex gap-[10px]"}>
            <ReactionButton
              type={"like"}
              count={Number(info?.reaction?.likes)}
            />
            <ReactionButton
              type={"dislike"}
              count={Number(info?.reaction?.dislikes)}
            />
            <button>
              <ShareIcon></ShareIcon>
            </button>
          </div>
          <button className={""}>
            <TabIcon></TabIcon>
          </button>
        </div>
      </div>
      <span className={"text-[12px]"}>{info?.challenge?.description}</span>
    </div>
  );
};
