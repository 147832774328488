import React from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as BackIcon } from "assets/icons/rightArrow/rightArrow.svg";

export const ButtonBack = () => {
  const navigate = useNavigate();
  return (
    <button
      className={"w-[24px] h-[24px] flex  items-center"}
      onClick={() => navigate(-1)}
    >
      <BackIcon width={6} height={12} />
    </button>
  );
};
