import { BaseThunkType, InferActionsType } from "store/store";
import { challengeActions } from "../actions";
import { IChallengeForm } from "interfaces";
import { challengesAPI } from "api/challenges";

type ThunkType = BaseThunkType<InferActionsType<typeof challengeActions>>;

export const searchChallenge =
  (value: string): ThunkType =>
  async (dispatch) => {
    try {
      dispatch(challengeActions.setIsLoading(true));
      dispatch(challengeActions.setSearchValue(value));
    } catch (e: any) {
      console.log(e);
    } finally {
      dispatch(challengeActions.setIsLoading(false));
    }
  };
export const createChallenge =
  (data: IChallengeForm): ThunkType =>
  async (dispatch) => {
    try {
      dispatch(challengeActions.setIsLoading(true));
      await challengesAPI.createChallenge(data);
    } catch (e: any) {
      console.log(e);
    } finally {
      dispatch(challengeActions.setIsLoading(false));
    }
  };

export const getCurrentChallenge =
  (id: string): ThunkType =>
  async (dispatch) => {
    try {
      dispatch(challengeActions.setIsLoading(true));
      const challenge = await challengesAPI.getCurrentChallenge(id);
      const posts = await challengesAPI.getChallengePost(id);
      const reaction = await challengesAPI.getChallengeReaction(id);
      dispatch(
        challengeActions.setCurrentChallenge({ challenge, posts, reaction })
      );
    } catch (e: any) {
      console.log(e);
    } finally {
      dispatch(challengeActions.setIsLoading(false));
    }
  };
