import React from "react";
import { IChallenge } from "interfaces";
import { NavLink } from "react-router-dom";
import { ROUTES } from "app/constants";

interface IProps {
  challenge: IChallenge;
}

export const Challenge = ({ challenge }: IProps) => {
  return (
    <NavLink
      to={`${ROUTES.CURRENT_CHALLENGE}/${challenge.id}`}
      className={"flex items-center mb-[20px]"}
    >
      <img
        src={challenge?.filePath}
        className={"w-[60px] h-[48px] min-w-[60px] min-h-[48px]"}
        alt={"challenge image"}
      />
      <span className={"font-medium truncate pl-[10px]"}>
        {challenge?.name}
      </span>
    </NavLink>
  );
};
