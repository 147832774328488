import { InferActionsType } from "store/store";
import { IChallenge, IChallengeInfo, IListedData } from "interfaces";
import {
  SET_CURRENT_CHALLENGE,
  SET_IS_LOADING,
  SET_RECOMMEND_CHALLENGES,
  SET_SEARCH_CHALLENGES,
  SET_SEARCH_VALUE_CHALLENGE,
} from "store/constants";
import { challengeActions } from "store/actions";

export type IChallengeActions = InferActionsType<typeof challengeActions>;

export interface IChallengeReducer {
  challenges: IListedData<IChallenge>;
  currentChallenge: IChallengeInfo;
  searchValue: string;
  isLoading: boolean;
}
const defaultState: IChallengeReducer = {
  challenges: {} as IListedData<IChallenge>,
  currentChallenge: {} as IChallengeInfo,
  searchValue: "",
  isLoading: false,
};

export const challengeReducer = (
  state = defaultState,
  action: IChallengeActions
): IChallengeReducer => {
  switch (action.type) {
    case SET_SEARCH_VALUE_CHALLENGE:
    case SET_SEARCH_CHALLENGES:
    case SET_CURRENT_CHALLENGE:
    case SET_RECOMMEND_CHALLENGES:
    case SET_IS_LOADING:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
