import React, { useEffect } from "react";
import { ReactComponent as MicIcon } from "assets/icons/mic.svg";
import SpeechRecognitionObj, {
  useSpeechRecognition,
} from "react-speech-recognition";

interface IProps {
  onChangeValue: (value: string) => void;
}

export const SpeechRecognition = ({ onChangeValue }: IProps) => {
  const { transcript, listening, browserSupportsSpeechRecognition } =
    useSpeechRecognition();
  useEffect(() => {
    if (transcript) {
      onChangeValue(transcript);
    }
  }, [transcript]);
  if (!browserSupportsSpeechRecognition) {
    return <></>;
  }

  return (
    <MicIcon
      className={`${
        listening ? `stroke-main_blue_text` : `stroke-[#808080]`
      } w-[18px] h-[18px] cursor-pointer`}
      onClick={() => {
        listening
          ? SpeechRecognitionObj.stopListening()
          : SpeechRecognitionObj.startListening();
      }}
    />
  );
};
