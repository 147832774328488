import React from "react";
import { Control } from "react-hook-form";
import { FileUpload } from "shared/form";
import { ButtonBack } from "../../../../shared/ui";

interface IProps {
  control: Control<any>;
}
export const UploadContent = ({ control }: IProps) => {
  return (
    <div>
      <div className={"w-full flex mb-[20px]"}>
        <ButtonBack />
        <span className={"w-full text-[16px] font-medium text-center"}>
          Все медиа
        </span>
        <button
          onClick={(event) => event.preventDefault()}
          className={"text-[12px] font-medium"}
        >
          Далее
        </button>
      </div>
      <div>
        <div className={"flex gap-[10px]"}>
          <FileUpload
            type={"image"}
            control={control}
            name={"fileId"}
            className={
              "w-full text-[12px] font-medium bg-main_blue_text text-white rounded-[6px] text-center py-[8px]"
            }
          >
            Фото
          </FileUpload>
          <FileUpload
            type={"video"}
            control={control}
            name={"fileId"}
            className={
              "w-full text-[12px] font-medium bg-main_blue_text text-white rounded-[6px] text-center py-[8px]"
            }
          >
            Видео
          </FileUpload>
        </div>
      </div>
    </div>
  );
};
