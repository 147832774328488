import React from "react";
import { IChallenge } from "interfaces";
import { Challenge } from "./challenge";

interface IProps {
  challenges: IChallenge[];
}
export const Recommendation = ({ challenges }: IProps) => {
  return (
    <div>
      <h1
        className={
          "font-medium text-[16px] leading-[19px] tracking-[0.03em] mb-[15px]"
        }
      >
        Рекомендованные челленджи
      </h1>
      <div className={"flex flex-col"}>
        {challenges?.map((el) => (
          <Challenge challenge={el} key={el.id} />
        ))}
      </div>
    </div>
  );
};
