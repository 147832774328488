import React, { useState } from "react";
import { ReactComponent as BackArrow } from "assets/icons/rightArrow/rightArrow.svg";
import { ReactComponent as AddPhotoIcon } from "assets/icons/addPhoto.svg";
import { FileUploader } from "react-drag-drop-files";
import { acceptedImagesTypes, ROUTES } from "app/constants";
import { NavLink, useNavigate } from "react-router-dom";

interface IProps {
  open: boolean;
  onClose: () => void;
}

export const NewPostModal = ({ open, onClose }: IProps) => {
  const navigate = useNavigate();

  const [file, setFile] = useState<File | null>(null);

  const handleChange = (file: File) => {
    setFile(file);
    if (file) {
      navigate(ROUTES.PREPARE_NEW_POST, { state: file });
      onCloseHandler();
      setFile(null);
    }
  };

  const onCloseHandler = () => {
    onClose();
    setFile(null);
  };

  if (!open) {
    return null;
  }
  return (
    <div
      onClick={onCloseHandler}
      className={
        "fixed top-0 left-0 z-50 w-full h-screen bg-modal_bg flex justify-center items-center"
      }
    >
      <div
        className={
          "w-[350px] h-[360px] bg-white rounded-[20px] px-[10px] py-[17px]"
        }
        onClick={(event) => event.stopPropagation()}
      >
        <div className={"w-full flex"}>
          <BackArrow
            onClick={onCloseHandler}
            className={"cursor-pointer w-[6px] h-[12px]"}
          />
          <span className={"w-full text-center font-medium text-[15px]"}>
            Создать
          </span>
        </div>
        <div className={"h-full flex flex-col justify-center items-center"}>
          <AddPhotoIcon className={"w-[50px] h-[50px] mb-[18px]"} />
          <FileUploader
            hoverTitle={"-"}
            handleChange={handleChange}
            name="file"
            types={acceptedImagesTypes}
          >
            <button
              className={
                "w-[170px] h-[30px] font-medium text-white rounded-[6px] bg-main_blue_gb text-[12px] mb-[10px]"
              }
            >
              Пост
            </button>
            {/*<NavLink*/}
            {/*  to={ROUTES.CREATE_CHALLENGE}*/}
            {/*  className={*/}
            {/*    "w-[170px] h-[30px] block flex  font-medium justify-center items-center text-white rounded-[6px] bg-main_blue_gb text-[12px]"*/}
            {/*  }*/}
            {/*>*/}
            {/*  Челленж*/}
            {/*</NavLink>*/}
          </FileUploader>
        </div>
      </div>
    </div>
  );
};
