import {
  SET_CURRENT_CHALLENGE,
  SET_IS_LOADING,
  SET_SEARCH_CHALLENGES,
  SET_SEARCH_VALUE_CHALLENGE,
} from "store/constants";
import { IChallenge, IChallengeInfo, IListedData } from "interfaces";

export const challengeActions = {
  setSearchValue: (searchValue: string) => ({
    type: SET_SEARCH_VALUE_CHALLENGE,
    payload: { searchValue },
  }),
  setCurrentChallenge: (currentChallenge: IChallengeInfo) => ({
    type: SET_CURRENT_CHALLENGE,
    payload: { currentChallenge },
  }),
  setChallenges: (challenges: IListedData<IChallenge>) => ({
    type: SET_SEARCH_CHALLENGES,
    payload: { challenges },
  }),
  setIsLoading: (isLoading: boolean) => ({
    type: SET_IS_LOADING,
    payload: { isLoading },
  }),
};
