import React from "react";
import { IChallenge } from "interfaces";
import { Challenge } from "../challenge/challenge";

interface IProps {
  challenges: IChallenge[];
}
export const Challenges = ({ challenges }: IProps) => {
  return (
    <div>
      {challenges?.map((el) => (
        <Challenge key={el.id} challenge={el} />
      ))}
    </div>
  );
};
