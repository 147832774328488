import React, { ChangeEvent, ReactNode, useRef } from "react";
import { Control, useController } from "react-hook-form";
import { fileAPI } from "api";

interface IProps {
  children: ReactNode;
  control: Control<any>;
  name: string;
  className: string;
  type: "video" | "image";
}

export const FileUpload = ({
  children,
  className,
  type,
  name,
  control,
}: IProps) => {
  const {
    field: { onChange },
  } = useController({ control, name });
  const inputRef = useRef<HTMLInputElement>(null);
  const handleChoose = () => {
    inputRef?.current?.click();
  };
  const onChangeHandler = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event?.target?.files?.[0];
    if (file && file.type.includes(type)) {
      const fileId = await fileAPI.uploadFile(file);
      onChange(fileId);
    }
  };

  return (
    <>
      <input
        ref={inputRef}
        className={"hidden"}
        type="file"
        onChange={onChangeHandler}
      />
      <button
        className={className}
        onClick={(event) => {
          event.preventDefault();
          handleChoose();
        }}
      >
        {children}
      </button>
    </>
  );
};
