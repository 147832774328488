import React, { DetailedHTMLProps, InputHTMLAttributes } from "react";
import cn from "classnames";

interface IProps
  extends DetailedHTMLProps<
    InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  register?: any;
  name?: string;
  placeholder?: string;
  appearance?: "default" | "error";
  type?: string;
  isPassword?: boolean;
  value?: string;
}

export const Input = ({
  register,
  name,
  placeholder,
  appearance = "default",
  type = "text",
  isPassword = false,
  value,
  ...rest
}: IProps) => {
  return (
    <input
      value={value}
      role="presentation"
      autoComplete={"off"}
      type={isPassword ? "password" : type}
      placeholder={placeholder}
      className={cn(
        "text-[16px] font-normal border  rounded-[20px] w-full py-[15px] px-[20px]  focus:outline-none focus:shadow-outline ",
        {
          ["focus:border-main_error_border border-main_error_border"]:
            appearance === "error",
        },
        {
          ["focus:border-main_blue_border border-main_grey_border"]:
            appearance === "default",
        }
      )}
      {...register(name)}
      {...rest}
    />
  );
};
