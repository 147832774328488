import React from "react";
import { Typography } from "shared/ui";
import { IPostsChallenge } from "interfaces";

interface IProps {
  posts: IPostsChallenge[];
}

export const Members = ({ posts }: IProps) => {
  return (
    <div>
      <Typography variant={"h3"} text={"Участники"} />
      <div className={"grid grid-cols-3"}>
        {posts?.map((el) => {
          return el.fileMediaType === "IMAGE" ? (
            <img
              src={el.filePath}
              alt={"post image"}
              className={"w-[110px] h-[110px]"}
            />
          ) : (
            <video
              src={el.filePath}
              className={"w-[110px] h-[110px]"}
              controls
            />
          );
        })}
      </div>
    </div>
  );
};
