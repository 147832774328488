import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Settings } from "./settings";
import { getMyUserData } from "store/selectors";
import { ISettingsForm } from "interfaces";
import { changeSettingsTC } from "store/thunks";
import { useNavigate } from "react-router-dom";

export const SettingsPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector(getMyUserData);

  const goBack = () => {
    navigate(-1);
  };
  const goMain = () => {
    navigate("/");
  };

  const onSubmitHandler = async (data: ISettingsForm) => {
    dispatch(changeSettingsTC(data, goMain));
  };

  return <Settings user={user} onSubmitHandler={onSubmitHandler} />;
};
