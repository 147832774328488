import React, { HTMLAttributes } from "react";
import { ReactComponent as SearchIcon } from "assets/icons/search.svg";
import { ReactComponent as CloseIcon } from "assets/icons/close.svg";
import { SpeechRecognition } from "../speechRecognition";

interface IProps extends HTMLAttributes<HTMLInputElement> {
  value: string;
  onChangeValue: (value: string) => void;
}
export const SearchInput = ({ onChangeValue, value, ...rest }: IProps) => {
  return (
    <div
      className={
        "w-full border rounded-[15px] border-[#BCC0CC] flex p-[12px] gap-[10px]"
      }
    >
      <SearchIcon className={"w-[18px] h-[18px]"} />
      <input
        className={"w-full outline-none text-[14px] placeholder-[#808080]"}
        value={value}
        onChange={(event) => onChangeValue(event.target.value)}
        {...rest}
      />
      {value ? (
        <CloseIcon
          onClick={() => onChangeValue("")}
          className={"w-[18px] h-[18px] cursor-pointer"}
        />
      ) : (
        <SpeechRecognition onChangeValue={onChangeValue} />
      )}
    </div>
  );
};
