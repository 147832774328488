import React from "react";
import { useForm } from "react-hook-form";
import { IChallengeForm } from "interfaces";
import { ShowContent, UploadContent } from "features/challenges";
import { useDispatch } from "react-redux";
import { createChallenge } from "store/thunks";
import { yupResolver } from "@hookform/resolvers/yup";
import { ChallengeSchema } from "app/constants";

export const CreateChallenge = () => {
  const { control, handleSubmit, watch } = useForm<IChallengeForm>({
    mode: "onChange",
    resolver: yupResolver(ChallengeSchema),
  });
  const dispatch = useDispatch();
  const onSubmit = (data: IChallengeForm) => {
    dispatch(createChallenge(data));
  };

  return (
    <form className={"p-[20px]"} onSubmit={handleSubmit(onSubmit)}>
      {watch("fileId") ? (
        <ShowContent control={control} file={watch("fileId")} />
      ) : (
        <UploadContent control={control} />
      )}
    </form>
  );
};
