import React from "react";
import { ButtonBack } from "shared/ui";
import { ReactComponent as EditIcon } from "assets/icons/edit.svg";

interface IProps {
  onParticipate: () => void;
  onEdit?: () => void;
  title: string;
}
export const Header = ({ onParticipate, onEdit, title }: IProps) => {
  return (
    <div className={"mb-[10px]"}>
      <div className={"flex mb-[20px]"}>
        <ButtonBack />
        <span className={"w-full text-center font-medium text-[16px]"}>
          Челлендж
        </span>
      </div>
      <div className={"flex justify-between"}>
        <span className={"text-[14px] font-medium"}>{title}</span>
        <div className={"flex items-center"}>
          <button
            onClick={onParticipate}
            className={
              "bg-black text-white text-[12px] px-[24px] rounded-[6px] font-medium"
            }
          >
            Поучаствовать
          </button>
          {onEdit && (
            <button onClick={onEdit} className={"w-[16px] h-[16px] ml-[5px]"}>
              <EditIcon />
            </button>
          )}
        </div>
      </div>
    </div>
  );
};
