import React, { HTMLAttributes } from "react";
import { Control, useController } from "react-hook-form";
import { ButtonBack } from "shared/ui";
interface IProps {
  file: string;
  control: Control<any>;
}
export const ShowContent = ({ file, control }: IProps) => {
  return (
    <div className={"w-full"}>
      <div className={"w-full flex mb-[20px]"}>
        <ButtonBack />
        <span className={"w-full text-[16px] font-medium text-center"}>
          Новый челлендж
        </span>
        <button type={"submit"} className={"text-[12px] font-medium"}>
          Опубликовать
        </button>
      </div>

      <div className={"flex flex-col gap-[10px]"}>
        {file ? (
          <video className=" w-full h-[300px]" controls src={file} />
        ) : (
          <img
            className={"w-full h-[300px]"}
            src={file}
            alt={"challenge photo"}
          />
        )}
        <Input
          name={"name"}
          control={control}
          placeholder={"Название челленджа"}
        />
        <TextArea
          name={"description"}
          control={control}
          placeholder={"Описание челленджа..."}
        />
      </div>
    </div>
  );
};
interface IPropss extends HTMLAttributes<HTMLInputElement> {
  name: string;
  control: Control<any>;
}
const Input = ({ name, control, ...rest }: IPropss) => {
  const {
    field: { value, onChange },
  } = useController({ name, control });
  return (
    <input
      value={value}
      onChange={onChange}
      className={`${rest.className} text-[14px] placeholder:text-main_grey_border w-full rounded-[15px] border border-black border-opacity-[0.4] p-[12px]`}
      {...rest}
    />
  );
};
interface IPropsss extends HTMLAttributes<HTMLTextAreaElement> {
  name: string;
  control: Control<any>;
}
const TextArea = ({ name, control, ...rest }: IPropsss) => {
  const {
    field: { value, onChange },
  } = useController({ name, control });
  return (
    <textarea
      value={value}
      onChange={onChange}
      className={`${rest.className} text-[14px] placeholder:text-main_grey_border w-full rounded-[15px] border border-black border-opacity-[0.4] p-[12px] resize-none h-[70px]`}
      {...rest}
    />
  );
};
