import React from "react";
import { SearchInput } from "shared/ui";
import { useDispatch, useSelector } from "react-redux";
import { getSearchChallenges, getSearchValueChallenge } from "store/selectors";
import { searchChallenge } from "store/thunks";
import { Challenges, Recommendation } from "features/challenges";

export const ChallengesPage = () => {
  const searchValue = useSelector(getSearchValueChallenge);
  const challenges = useSelector(getSearchChallenges);
  const dispatch = useDispatch();
  const onChangeValue = (value: string) => {
    dispatch(searchChallenge(value));
  };
  return (
    <div className={"p-[20px] w-full flex flex-col gap-[20px]"}>
      <SearchInput
        value={searchValue}
        onChangeValue={onChangeValue}
        placeholder={"Поиск"}
      />
      {searchValue ? (
        <Challenges challenges={challenges} />
      ) : (
        <Recommendation challenges={challenges} />
      )}
    </div>
  );
};
