import { instance } from "./instance";
import {
  IChallenge,
  IChallengeForm,
  IListedData,
  IPostsChallenge,
  IQueryParams,
  IReaction,
} from "../interfaces";

export const challengesAPI = {
  getChallenges(queryParams?: IQueryParams) {
    return instance.get<IListedData<IChallenge>>(
      `/challenges?query=${queryParams?.query || ""}&page=${
        queryParams?.page || "0"
      }&size=${queryParams?.size || "1"}&sort=${queryParams?.sort || "string"}`
    );
  },
  getCurrentChallenge(id: string): Promise<IChallenge> {
    return instance.get(`/challenge/${id}`);
  },
  getChallengePost(id: string): Promise<IListedData<IPostsChallenge>> {
    return instance.get(`/challenge/${id}/posts`);
  },
  getChallengeReaction(id: string): Promise<IReaction> {
    return instance.get(`/challenge/${id}/reactions`);
  },
  createChallenge(data: IChallengeForm) {
    return instance.post(`/challenge`, data);
  },
};
