import { RootStateType } from "store/store";

export const getSearchValueChallenge = (state: RootStateType) =>
  state.challenge.searchValue;

export const getSearchChallenges = (state: RootStateType) =>
  state.challenge.challenges.content;

export const getCurrentChallenge = (state: RootStateType) =>
  state.challenge.currentChallenge;

export const getIsLoadingValue = (state: RootStateType) =>
  state.challenge.isLoading;
