import React, { HTMLAttributes } from "react";
import { ReactComponent as LikeIcon } from "assets/icons/like.svg";
import { ReactComponent as DislikeIcon } from "assets/icons/dislike.svg";

interface IProps extends HTMLAttributes<HTMLButtonElement> {
  type: "like" | "dislike";
  count: number;
}
export const ReactionButton = ({ type, count, ...rest }: IProps) => {
  return (
    <button className={`${rest.className} flex items-center`} {...rest}>
      {type === "like" ? (
        <LikeIcon className={"w-[16px] h-[16px]"} />
      ) : (
        <DislikeIcon className={"w-[16px] h-[16px]"} />
      )}
      <span className={"ml-[5px] text-[14px] font-medium"}>{count}</span>
    </button>
  );
};
