import { ISignInForm, ISignUpForm } from "interfaces";
import { instance } from "./instance";

export const authAPI = {
  authMe(timeZone: string) {
    return instance.post(`/me?zoneId=${timeZone}`);
  },
  getTimeZones() {
    return instance.post("/constants/timezones");
  },

  register(data: ISignUpForm) {
    return instance.post(`/auth/join`, {
      nickname: data.nickname,
      email: data.email,
      password: data.password,
      passwordCheck: data.passwordCheck,
      zoneId: data.zoneId,
      birthday: data.birthday,
    });
  },

  login({ username, password }: ISignInForm) {
    return instance.post("/auth/authenticate", {
      username: username,
      password: password,
    });
  },

  confirmRegister(token: string) {
    //TODO check me
    return instance.post("/auth/confirm", {
      token: token,
      email: "ivvwbpsolebzbye@candassociates.com",
      name: "pav",
    });
  },
};
