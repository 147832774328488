import React from "react";
import { ReactComponent as MainIcon } from "assets/icons/bottomNavigation/main.svg";
import { ReactComponent as AddPostIcon } from "assets/icons/bottomNavigation/addPost.svg";
import { ReactComponent as ProfileIcon } from "assets/icons/bottomNavigation/profile.svg";
import { ReactComponent as ChallengesChoseIcon } from "assets/icons/bottomNavigation/challengeChose.svg";
import { ReactComponent as ChallengesIcon } from "assets/icons/bottomNavigation/challenge.svg";
import { ReactComponent as MessageIcon } from "assets/icons/bottomNavigation/message.svg";
import { Link, useLocation } from "react-router-dom";
import { ROUTES } from "app/constants/routes";
import { useDispatch, useSelector } from "react-redux";
import { getMyUserData } from "store/selectors";
import { postActions } from "store/actions";
import styles from "./styles.module.css";

export const BottomNavigation = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { user } = useSelector(getMyUserData);

  const newPostModalHandler = () => {
    dispatch(postActions.setOpenNewPostModalAC(true));
  };

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <Link to={ROUTES.MAIN}>
          <MainIcon />
        </Link>
        {/*<Link to={ROUTES.CHALLENGES}>*/}
        {/*  {location.pathname === ROUTES.CHALLENGES ? (*/}
        {/*    <ChallengesChoseIcon />*/}
        {/*  ) : (*/}
        {/*    <ChallengesIcon />*/}
        {/*  )}*/}
        {/*</Link>*/}
        <span className={"cursor-pointer"} onClick={newPostModalHandler}>
          <AddPostIcon />
        </span>
        {/*<Link to={`/`}>*/}
        {/*  <MessageIcon />*/}
        {/*</Link>*/}
        <Link to={`/${user?.nickname}`}>
          <ProfileIcon />
        </Link>
      </div>
    </div>
  );
};
