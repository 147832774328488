import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentChallenge } from "store/selectors";
import { Content, Header, Members } from "features/challenges";

export const CurrentChallengePage = () => {
  const params = useParams();
  const info = useSelector(getCurrentChallenge);
  const dispatch = useDispatch();
  useEffect(() => {}, []);
  if (!info?.challenge)
    return (
      <div className={"text-center text-[16px] font-medium"}>
        Такого челленжа не найдено{" "}
      </div>
    );

  return (
    <div className={"p-[20px]"}>
      <Header title={info?.challenge?.name} onParticipate={() => {}} />
      <Content
        info={info}
        onParticipate={() => {}}
        onLike={() => {}}
        onDislike={() => {}}
        onTab={() => {}}
      />
      <Members posts={info?.posts?.content} />
    </div>
  );
};
